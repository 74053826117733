// Auth.js
import React, { useEffect } from "react";
import "./App.css";
import router from "./route";
import { createTheme, ThemeProvider } from "@mui/material";
import { RouterProvider, } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bannerData, fetchEnv } from "./reducers/resourceReducer";
import RouteChangeTracker from "./components/RouteChangeTracker";

const theme = createTheme({
  palette: {
    brand: {
      main: "#15ad87",
      dark: "#129b79",
    },
    brandDark: {
      main: "#36454F",
      text: "white",
    },
  },
});

const Auth = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    
    dispatch(fetchEnv());
    dispatch(bannerData());
  }, [dispatch]);
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router}>
        <RouteChangeTracker />
      </RouterProvider>
      
    </ThemeProvider>
  );
};

export default Auth;
