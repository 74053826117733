import { Box, CircularProgress, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CroboContainer from "../../components/croboContainer";
import { getRedeemHistory, resendGcCode } from "../../reducers/userReducer";
import RedeemHistoryCard from "../../components/redeemHistoryCard";

const RedeemHistory = () => {
    const store = useSelector((state) => state.userAuth);
    // const [page, setPage] = useState(1);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getRedeemHistory({
            page: 1,
            pageSize: 10,
            filter: 0
        }));
    }, [dispatch]);

    const onClickResendCode = (id) => {
        dispatch(resendGcCode({
            id
        }))
    }


    return (
        <CroboContainer>
            {store.redeemHistory?.length >= 1 ? (
                <Box>
                    {store.loading ? (
                        <Box display={"flex"} justifyContent={"center"}>
                            <CircularProgress color="brand" />
                        </Box>
                    ) : (
                        <Container>
                            {store.redeemHistory?.map((t) => (
                                <RedeemHistoryCard
                                    key={t.id}
                                    id={t.id}
                                    giftCardCode={t.incentive_details?.gcClaimCode}
                                    statusOfGiftCard={t.incentive_details?.cardInfo?.cardStatus}
                                    creationDate={t.gc_creation_date}
                                    amount={t.amount}
                                    redeemStatus={t.referral_status}
                                    onClick={onClickResendCode}
                                />
                            ))}
                        </Container>
                    )}
                </Box>
            ) : (
                <Box display={"flex"} justifyContent={"center"} alignItems={'center'} sx={{ height: "200px" }}>
                    <Typography alignItems={"center"} variant="h5">No Redeem History</Typography>
                </Box>
            )}
        </CroboContainer>
    );
};

export default RedeemHistory;
