import { TextField, Button, Box, Typography, InputAdornment, CircularProgress } from '@mui/material';
import { Container } from '@mui/system';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';
import AccountBalance from '@mui/icons-material/AccountBalance';
import Code from '@mui/icons-material/Code';
import { useLocation, useNavigate } from "react-router-dom";
import CroboContainer from "../../components/croboContainer";
import { useState } from 'react';
import { updateRecipient } from '../../reducers/userReducer';
import { ToastContainer, toast } from 'react-toastify';

async function sleep () {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, 2000)
    });
}

const EditRecipient = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [name, setName] = useState(state.name);
    const [email, setEmail] = useState(state.email);
    const [ifsc, setIfsc] = useState(state.ifsc);
    // const [bankName, setBankName] = useState(state.bank_name);
    const [accountNo, setAccountNo] = useState(state.bank_account_number);
    const [phone, setPhone] = useState(state.phone);
    const [loading, setLoading] = useState(false);
    const notify = () => toast.success("Successful");
    return (
        <CroboContainer>
            <Box position={'relative'}>
                <Container maxWidth="sm" sx={{
                    marginTop: 4,
                    width: '75%',
                    minWidth: '320px'
                }}>
                    <Typography variant="h6" align="left" gutterBottom>
                        Enter Recipient Details
                    </Typography>
                    <Typography sx={{
                        color: 'grey.600'
                    }} variant='body2' gutterBottom>
                        Note: NRE accounts are not supported currently.
                    </Typography>
                    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 2 }}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            value={name}
                            required
                            disabled
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            required
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            value={email}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* <TextField
                        label="PAN Number"
                        variant="outlined"
                        value={pan}
                        onChange={(e) => {
                            setPan(e.target.value)
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CreditCard />
                                </InputAdornment>
                            ),
                        }}
                    /> */}
                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            type="tel"
                            required
                            disabled
                            onChange={(e) => {
                                setPhone(e.target.value)
                            }}
                            value={phone}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Phone />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* <TextField
                            label="Bank Name"
                            variant="outlined"
                            value={bankName}
                            required
                            disabled
                            onChange={(e) => {
                                setBankName(e.target.value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountBalance />
                                    </InputAdornment>
                                ),
                            }}
                        /> */}
                        <TextField
                            label="Bank Account Number"
                            variant="outlined"
                            type="number"
                            value={accountNo}
                            disabled
                            onChange={(e) => {
                                setAccountNo(e.target.value)
                            }}
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountBalance />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="IFSC Code"
                            variant="outlined"
                            value={ifsc}
                            required
                            disabled
                            onChange={(e) => {
                                setIfsc(e.target.value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Code />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box display={'flex'} justifyContent={'flex-end'} gap={2}>
                            <Button variant="text" type="button" sx={{
                                color: 'grey.600'
                            }}
                                onClick={() => {
                                    navigate('/manage-recipients')
                                }}
                            >
                                Cancel
                            </Button>
                            <Button color='brand' disabled={loading} variant="contained" type="submit"
                                onClick={async (e) => {
                                    e.preventDefault();
                                    setLoading(true);
                                    await updateRecipient({
                                        id: state.id,
                                        user_name: name,
                                        email: email
                                    });
                                    notify();
                                    await sleep();
                                    navigate('/manage-recipients');
                                }}>
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Container>

                <Box position={'absolute'} top={'50%'} left={'50%'} sx={{
                    height: '100%',
                    width: '100%',
                    transform: 'translate(-50%, -50%)',
                    display: loading ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignContent: 'center'
                }}>
                    <Box margin={'auto'}>
                        <CircularProgress color='brand' />
                    </Box>
                </Box>
            </Box>
            <ToastContainer
                    hideProgressBar
                    position="top-center"
                />
        </CroboContainer>
    )
}

export default EditRecipient;