import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { amountRoundOff } from "../helper/helper";
const TransactionCard = ({
  id,
  status,
  exchangeRate,
  UtrNo,
  receiverName,
  receiveInBank,
  tranId,
  sourceAmount,
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const transactionStatus = (status, receiveInBank) => {
    if (status === 2) {
      return {
        color: "red",
        message: "Transaction Failed",
      };
    }
    if ((status === 1 && receiveInBank === 0) || status === 0) {
      return {
        color: "#FFC300",
        message: "Transaction Processing",
      };
    }
    return {
      color: "green",
      message: "Transaction Added",
    };
  };

  return (
    <Box
      sx={{
        p: 2,
        boxShadow: 1,
        borderRadius: 4,
        my: 2,
        bgcolor: "grey.200",
        cursor: "pointer",
        "&:hover": {
          boxShadow: 5,
        },
      }}
      onClick={handleClick}
      key={id}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            fontWeight="500"
            fontSize="20px"
            color={transactionStatus(status, receiveInBank).color}
            margin={0}
          >
            {receiverName
              ? receiverName
              : transactionStatus(status, receiveInBank).message}
          </Typography>
          <Typography color="text.secondary" variant="body2" lineHeight={1}>
            Exchange Rate: $1 = ₹{amountRoundOff(exchangeRate)}
          </Typography>
          <Typography color="text.secondary" variant="body2" lineHeight={1}>
            {UtrNo && `UTR No. ${UtrNo}`}
          </Typography>
          <Typography color="text.secondary" variant="body2" lineHeight={1}>
            TransactionID: {tranId}
          </Typography>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color={transactionStatus(status, receiveInBank).color}
          >
            ${amountRoundOff(sourceAmount)}
          </Typography>
          {/* <Typography lineHeight={1} variant="body2" color={'black'}>{convertToDate(transactionalTime)}</Typography> */}
        </Box>
      </Stack>
    </Box>
  );
};

export default TransactionCard;
