// src/VerifyAccount.js
import React, { useEffect, useRef ,useState} from "react";
import { Typography, Box } from "@mui/material";
import VerificationStepper from "./VerificationStepper";
import VerificationStatus from "./VerificationStatus";
import ActionButtons from "./ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { remitStatusUpdate } from "../../reducers/userReducer";
import CroboContainer from "../../components/croboContainer";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";

const VerifyAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bankBypass, setBankBypass] = useState(false);
  const {
    bank,
    bankVerification,
    kyc,
    guid,
    token,
    showDocumentsButton,
    customMessage,
    remitLoading,
    loading
  } = useSelector((state) => state.userAuth);

  useEffect(() => {
    console.log("onboarding remit")
    if(remitLoading){
      if (bank === 1 && bankVerification === 1 && (kyc === 1 || kyc === 5)) {
        navigate("/transaction");
      }
    }
    else{
      dispatch(remitStatusUpdate({ token }));
    }
    // Cleanup function
    return () => {
    };
  }, [dispatch, token, bankVerification, bank, kyc, navigate]);

  return (
    <CroboContainer>
      <Box padding={2}>
        {!remitLoading ? (
          <Loader />
        ) : (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              Verify Account
            </Typography>
            <VerificationStepper
              state={{
                bankBypass,
                setBankBypass,
                bank,
                bankVerification,
                kyc,
                guid,
                token,
                showDocumentsButton,
              }}
            />
            <Box mt={2}>
              <VerificationStatus
                state={{
                  bankBypass,
                  setBankBypass,
                  bank,
                  bankVerification,
                  kyc,
                  guid,
                  token,
                  showDocumentsButton,
                  remitLoading,
                  loading
                }}
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
              <ActionButtons
                state={{
                  bankBypass,
                  setBankBypass,
                  bank,
                  bankVerification,
                  kyc,
                  guid,
                  token,
                  showDocumentsButton,
                  customMessage,
                  loading
                }}
              />
            </Box>
          </>
        )}
      </Box>
    </CroboContainer>
  );
};

export default VerifyAccount;
