import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  apiRoutes,
  frequentContact,
  setLoader,
  remitStatusUpdate,
} from "../../reducers/userReducer";
import Loader from "../../components/loader";
import { Link } from "react-router-dom";
import { processName, analytics } from "../../helper/helper"; // Import analytics
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Transactions() {
  const [phone, setPhone] = useState("");
  const [recipient, setRecipient] = useState("myself");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    frequentContacts,
    loading,
    bank,
    bankVerification,
    kyc,
    token,
    senderPhone,
  } = useSelector((state) => {
    const data = state.userAuth;
    return {
      ...data,
      senderPhone: data.phone,
    };
  });
  const [error, setError] = useState(null);

  const handleStartTransfer = async () => {
    const storePhone = localStorage.getItem("phone");
    const number = Math.floor(Math.random() * 90000) + 10000;

    if (phone) {
      if (phone.length !== 10 || isNaN(phone)) {
        setError("Invalid phone number. It should be exactly 10 digits.");
        analytics.track("Invalid Phone Number Entered", { phone }); // Track invalid phone number
      } else {
        setError(null);
        analytics.track("Transfer Started with Phone", { phone }); // Track transfer start with phone number
        await checkUserAndNavigate(phone);
      }
    } else {
      setError(null);
      const phone = storePhone.substring(7) + number;
      analytics.track("Transfer Started without Phone", { phone }); // Track transfer start without phone number
      await checkUserAndNavigate(phone);
    }
  };

  const checkUserAndNavigate = async (phone) => {
    dispatch(setLoader(true));
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "post",
        url: apiRoutes.fetchUsers,
        data: {
          client_id: "APIGetBitRemitWeb",
          country_code: "+91",
          phone_number: phone,
        },
        headers: { "x-auth-token": token },
      };
      const { data } = await axios.request(config);
      if (data.status_code === 404) {
        navigate(`/transaction/create/${phone}/new`);
        analytics.track("User Not Found, Redirect to Create New Transaction", {
          phone,
        }); // Track when a new user is not found
      } else {
        navigate(`/transaction/create/${phone}`);
        analytics.track("Existing User Found, Redirect to Create Transaction", {
          phone,
        }); // Track when an existing user is found
      }
      dispatch(setLoader(true));
    } catch (error) {
      analytics.track("Error in Fetching User", { phone, error }); // Track any error in fetching user data
    }
  };

  useEffect(() => {
    dispatch(remitStatusUpdate({ token }));

    if (bank === 1 && bankVerification === 1 && (kyc === 1 || kyc === 5)) {
      // Valid state
      dispatch(frequentContact());
      analytics.track("Frequent Contacts Loaded"); // Track frequent contacts loaded
    } else {
      navigate("/onboarding");
      analytics.track(
        "Redirected to Onboarding due to Invalid Bank/Verification/KYC"
      ); // Track redirection to onboarding
    }
  }, [dispatch, bank, bankVerification, kyc, navigate]);

  return (
    <CroboContainer>
      <Container>
        <Box sx={{ paddingTop: 3 }}>
          <Typography variant="h5">Add New Recipient</Typography>

          <Select
            id="recipient"
            sx={{ marginTop: 2, width: "100%" }}
            value={recipient}
            onChange={(e) => {
              setRecipient(e.target.value);
              setPhone("");
              analytics.track("Recipient Selected", {
                recipient: e.target.value,
              }); // Track recipient selection
            }}
          >
            <MenuItem value={"myself"}>Myself</MenuItem>
            <MenuItem value={"someoneElse"}>Someone else</MenuItem>
          </Select>
          <OutlinedInput
            placeholder="Phone Number (Optional)"
            value={phone}
            type="number"
            sx={{ marginTop: 2, width: "100%" }}
            startAdornment={
              <InputAdornment position="start">+91</InputAdornment>
            }
            onChange={(e) => {
              setPhone(e.target.value);
              if (e.target.value === senderPhone.slice(2)) {
                setError("Enter a valid Indian number");
                analytics.track("Invalid Indian Phone Number Entered", {
                  phone: e.target.value,
                }); // Track invalid Indian phone number
              } else if (error) {
                setError(null);
              }
            }}
            inputProps={{ maxLength: 10 }}
          />
          {error && (
            <Typography textAlign={"center"} color={"red"}>
              {error}
            </Typography>
          )}
        </Box>
        {frequentContacts.length > 0 && (
          <Box sx={{ paddingTop: 2 }}>
            <Typography sx={{ paddingBottom: 0 }} variant="h5">
              Most frequent Contact
            </Typography>
            {loading ? (
              <Loader />
            ) : (
              <Grid
                display={"flex"}
                overflow={"scroll"}
                style={{
                  padding: 15,
                  overflowY: "hidden",
                }}
              >
                {frequentContacts.map((f) => {
                  return (
                    <Grid
                      key={f.user_id}
                      sx={{ maxWidth: 120 }}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Link
                        to={`/transaction/create/${f.phone}`}
                        style={{ textDecoration: "none" }}
                        onClick={() =>
                          analytics.track("Frequent Contact Selected", {
                            name: f.user_name,
                            phone: f.phone,
                          })
                        } // Track frequent contact selection
                      >
                        <ButtonBase>
                          <Avatar
                            sx={{
                              bgcolor: "brand.main",
                              width: 80,
                              height: 80,
                              fontSize: 35,
                              marginX: 2,
                              marginY: 1,
                            }}
                          >
                            {processName(f.user_name).letters}
                          </Avatar>
                        </ButtonBase>
                        <Typography textAlign={"center"} color={"black"}>
                          {processName(f.user_name).words}
                        </Typography>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Box>
        )}

        <Button
          color="brand"
          onClick={handleStartTransfer}
          variant="contained"
          sx={{ marginTop: 2, width: "100%" }}
          size="large"
          disabled={error !== null}
        >
          <Typography variant="h6" color={"black"}>
            Start Transfer
          </Typography>
        </Button>
      </Container>
    </CroboContainer>
  );
}

export default Transactions;
