// Auth.js
import React, { useEffect, useState } from "react";
import { Container, Button, Box, Typography, Grid } from "@mui/material";
import { markPinDisable, verifyPin } from "../../reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import ResetPin from "./resetPin";
import {
  auth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  firebaseErrorHandler,
} from "../../service/firebase";
import CroboContainer from "../../components/croboContainer";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import OTPInput from "react-otp-input";

const VerifyPin = ({ sendOtpAndResetPin }) => {
  const [pin, setPin] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, loading, pinVerified } = useSelector(
    (state) => state.userAuth
  );
  const handleOtp = async () => {
    if (pin.length < 4) {
    }
    dispatch(verifyPin(pin));
  };

  useEffect(() => {
    if (pinVerified) {
      navigate("/transaction");
      dispatch(markPinDisable());
    }
  }, [navigate, pinVerified, dispatch]);
  return (
    <Container>
      <Box paddingTop={10} paddingBottom={4}>
        <Typography textAlign={"center"} variant={"h5"}>
          ENTER PIN
        </Typography>
      </Box>

      <Box>
        <OTPInput
          value={pin}
          label="PIN"
          inputType="number"
          shouldAutoFocus="true"
          onChange={setPin}
          numInputs={4}
          renderSeparator={<span style={{ margin: 5 }}></span>}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                width: "100%",
                height: "40px",
                fontSize: 18,
                textAlign: "center",
                fontFamily: "Arial",
              }}
            />
          )}
        />
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        {error && (
          <Typography textAlign={"center"} sx={{ color: "red" }}>
            {error}
          </Typography>
        )}
      </Box>
      <Box paddingY={2}>
        {loading ? (
          <Loader />
        ) : (
          <Button
            color="brand"
            onClick={handleOtp}
            variant="contained"
            fullWidth
            size="large"
            disabled={pin.length !== 4 ? true : false}
          >
            <Typography variant="h6">Proceed</Typography>
          </Button>
        )}
      </Box>

      <Grid display={"flex"} justifyContent={"end"}>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            sendOtpAndResetPin();
          }}
        >
          Reset Pin
        </Button>
      </Grid>
    </Container>
  );
};

const Pin = () => {
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [cooldown, setCooldown] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    if (!window.recaptchaVerifier) {
      auth.appVerificationDisabledForTesting = true;
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
          },
        }
      );
      window.recaptchaVerifier.render().catch((error) => {
        console.error("Recaptcha render failed:", error);
      });
    }
  }, []);

  const sendOtp = async () => {
    
    try {
      if (cooldown) {
        setError("Please wait a moment before retrying.");
        return;
      }
      const appVerifier = window.recaptchaVerifier;
      const phone = localStorage.getItem("phone");
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phone,
        appVerifier
      );
      setConfirmationResult(confirmationResult);
      setOtpSent(true); 
    } catch (error) {
      setError(firebaseErrorHandler(error.code));

      if (error.code === "auth/too-many-requests") {
        setCooldown(true);
        setTimeout(() => setCooldown(false), 60000); // Set cooldown for 60 seconds
      }
    }
  };

  const sendOtpAndResetPin = async () => {
    await sendOtp();
  };
  return (
    <CroboContainer>
      {otpSent ? (
        <ResetPin confirmationResult={confirmationResult} error={error} />
      ) : (
        <VerifyPin sendOtpAndResetPin={sendOtpAndResetPin} />
      )}
    </CroboContainer>
  );
};

export default Pin;
