import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CroboContainer from '../components/croboContainer'
import { Container, Typography, Box, Button, Grid, Link, TextField, Alert } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';
import usFlag from '../assets/us.png';
import indiaFlag from '../assets/in.png';
import AG from '../assets/ag.jpg';
import CM from '../assets/cm.jpg';
import { subscribeUser, clearMessage } from '../reducers/userReducer';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const { customMessage, error } = useSelector((state) => {
        return state.userAuth;
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(subscribeUser(email));
        setEmail('');
    };

    const handleOnChange = (e) =>{
        setEmail(e.target.value);
    };

    const handleStartTransfer = () => {
        navigate('/pin');
    };

    useEffect(() => {
        if (customMessage || error) {
            const timer = setTimeout(() => {
                dispatch(clearMessage());
            }, 30000);

            return () => clearTimeout(timer);
        }
    }, [customMessage, error, dispatch]);
    return (
        <>
            <CroboContainer>
                <Container >
                    <Box my={4} textAlign="center">
                        <Typography sx={{ color: '#36454F', fontWeight: 'bold' }} variant="h4" component="h2" gutterBottom>
                            About Us
                        </Typography>
                        <Typography sx={{ color: '#36454F', fontWeight: 'bold' }} marginTop={4} variant="body1" paragraph>
                            Welcome to our platform, your go-to solution for the quickest and fee-free gifting and money transfer services from the USA to India. We're committed to providing you with a seamless and secure way to send gifts and money to your loved ones across borders, without any extra charges. Our customer-centric approach, combined with cutting-edge technology, ensures that your hard-earned money reaches its destination swiftly and without any hassle. Join us in making gifting & money transfers a breeze while saving you time and money. Your trust is our priority as we facilitate the fastest and fee-free money sending experience.
                        </Typography>
                        <Button variant="contained"
                            onClick={handleStartTransfer}
                            type='submit'
                            color="primary" 
                            size="large" 
                            sx={{
                            backgroundColor: "brand",
                            color: 'white',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: "#128d6f",
                            }, boxShadow: 3,
                            marginTop: 4,
                        }}>
                            Start Transfer
                        </Button>
                        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                            <Typography variant="body1" component="span" sx={{ color: "#36454F", fontWeight: "bold" }}>
                                - - - -
                            </Typography>
                            <img src={usFlag} alt="USA Flag" style={{ marginRight: 12 }} width={40} height={25} />
                            <img src={indiaFlag} alt="India Flag" style={{ marginLeft: 12 }} width={40} height={25} />
                            <Typography variant="body1" component="span" sx={{ color: "#36454F", fontWeight: "bold" }}>
                                - - - -
                            </Typography>
                        </Box>
                        <Typography sx={{ color: '#36454F' }} variant="body1" component="span">
                            <span style={{ fontWeight: 'bold' }}>US</span> to <span style={{ fontWeight: 'bold' }}>INDIA</span> in few steps
                        </Typography>
                    </Box>

                    <Box my={4}>
                        <Typography sx={{ color: '#36454F', fontWeight: 'bold' }} variant="h4" component="h2" gutterBottom textAlign="center">
                            Meet our Team
                        </Typography>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={12} sm={6} md={4} textAlign="center">
                                <img alt={"Abhay Agarwal"} src={AG} style={{ width: 100, height: 100, borderRadius: '50%', margin: '0 auto' }} />
                                <Typography variant="h6" component="h3" gutterBottom>
                                    {"Abhay Agarwal"}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {"CEO"}
                                </Typography>
                                <Link href="https://www.linkedin.com/in/aabhay/" target="_blank" rel="noopener noreferrer">
                                    <LinkedIn sx={{ fontSize: 50 }} color="primary" />
                                </Link>
                            </Grid>
                            <Grid item xs={20} sm={12} md={4} textAlign="center">
                                <img alt={'Chandni Malik'} src={CM} style={{ width: 100, height: 100, borderRadius: '50%', margin: '0 auto' }} />
                                <Typography variant="h6" component="h3" gutterBottom>
                                    {'Chandni Malik'}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {'Chief Communication Officer'}
                                </Typography>
                                <Link href="https://www.linkedin.com/in/chandni-malik-2309b14/" target="_blank" rel="noopener noreferrer">
                                    <LinkedIn sx={{ fontSize: 50 }} color="primary" />
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        my={4}
                        textAlign="center"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        mt={5}
                        mx="auto"
                        width="100%"
                    >
                        <Typography sx={{ color: '#36454F', fontWeight: 'bold' }} variant="h4" mb={2}>
                            Subscribe to our newsletter
                        </Typography>
                        <Box display="flex" alignItems="center" width="100%" gap={4}>
                            <TextField
                                variant='standard'
                                type='email'
                                placeholder="Enter your email"
                                margin="normal"
                                sx={{
                                    bgcolor: '#f5f5f5',
                                    marginTop: 0,
                                    borderRadius: 4,
                                    paddingLeft: 2,
                                    paddingRight: 2
                                }}
                                fullWidth
                                onChange={handleOnChange}
                                value={email}
                            />
                            <Button
                                onClick={handleSubmit}
                                type="submit"
                                variant="contained"
                                color="primary" size="large" sx={{
                                    backgroundColor: "brand",
                                    color: 'white',
                                    borderRadius: '8px',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: "#128d6f",
                                    }, boxShadow: 3,
                                }}
                            >
                                Subscribe
                            </Button>
                        </Box>
                    </Box>
                    {customMessage && (
                        <Alert
                            severity="success"
                            sx={{ mt: 2, width: '100%' }}
                        >
                            {customMessage}
                        </Alert>
                    )}
                    {error && (
                        <Alert
                            severity="error"
                            sx={{ mt: 2, width: '100%' }}
                        >
                            {error}
                        </Alert>
                    )}
                </Container>
            </CroboContainer>
        </>
    )
}

export default AboutUs
