// helpers/pollingHelper.js

export const startPolling = async (dispatch, action, token, n, loader, interval = 1000) => {

  const maxTime = 0.5 * 60 * 1000; // 30 minutes in milliseconds
  const startTime = Date.now();

  while (Date.now() - startTime < maxTime) {
    try {
      // Show loader
      // await dispatch(loader(true));

      // Wait for the interval before making the next dispatch
      await new Promise(resolve => setTimeout(resolve, interval));

      // Dispatch the action with the token
      await dispatch(action({ token }));
    } catch (error) {
      console.error(`Polling error:`, error);
      // Handle error if necessary
    }
  }
  
  // Hide loader
  // await dispatch(loader(false));

  return false;
};
