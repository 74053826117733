import { Box, Typography } from "@mui/material";
import React from "react";

const ErrorComponent = ({error}) => {
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Typography textAlign={"center"} sx={{ color: "red" }}>
        {error}
      </Typography>
    </Box>
  );
};

export default ErrorComponent;
