export function processName(sentence) {
  const words = sentence?.split(" ");
  const firstTwoWords = words?.slice(0, 2);
  const upperCaseWords = firstTwoWords?.map((word) => word.toUpperCase());
  const firstLetters = upperCaseWords?.map((word) => word.charAt(0));
  const resultLetters = firstLetters?.join("");
  const resultWords = upperCaseWords?.join(" ");

  return {
    letters: resultLetters,
    words: resultWords,
  };
}

export const amountRoundOff = (amount, precision = 2) => {
  const regex = new RegExp(
    "^\\d+(?:\\.\\d{0," + precision.toString() + "})?",
    ""
  );
  if (amount) return Number(amount.toString().match(regex));
  else return 0;
};

export const handleError = async (error) => {
  const response = {
    message: "",
    code: 0,
  };
  if (error.response) {
    response.message = error.response.data.message;
  } else if (error.request) {
    response.message = "No response received from server";
  } else {
    response.message = error.message;
  }
  response.code = error.response.data.response_code;
  analytics.track(`ERROR ${response.message}`, {
    ...error,
  });
  return response;
};

export function matchIsNumeric(text) {
  const isNumber = typeof text === "number";
  const isString = typeof text === "string";
  return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
}

export const handleOpenChat = () => {
  const profile = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : null;

  if (profile) {
    window.fcWidget.setExternalId(profile.user_id);
    window.fcWidget.user.setFirstName(profile.unverified_user_name);
    window.fcWidget.user.setEmail(profile.unverified_email);
    window.fcWidget.user.setProperties({
      phone: localStorage.getItem("phone")
        ? localStorage.getItem("phone")
        : null,
    });
  }
  window.fcWidget.open();
};
export const errorAnalytics = (message, err = null) => {
  const phone = localStorage.getItem("phone");
  analytics.track(`ERROR ${message}`, {
    ...err,
    phone: phone ? phone : null,
  });
};

export function convertToHourMinute(isoString) {
    const date = new Date(isoString);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

export function convertToDate(isoString) {
  const d = new Date(isoString);
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth();
  const day = d.getUTCDate();
  return `${day}/${month+1}/${year}`;
}

export const analytics = window.analytics;
