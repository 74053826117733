import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // localStorage.clear();
  }, [dispatch]);
  return true ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
