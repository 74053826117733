import axios from "axios";
import { apiRoutes } from "../reducers/userReducer";

export const fetchSenderUserProfile = async () => {
  const phone = localStorage.getItem("phone");
  const token = localStorage.getItem("token");
  let config = {
    method: "post",
    url: apiRoutes.fetchUsers,
    data: {
      client_id: "APIGetBitRemitWeb",
      country_code: "+1",
      phone_number: phone.substring(2),
      user_type: "sender",
    },
    headers: { "x-auth-token": token },
  };
  const payload = await axios.request(config);
  const userInfo = payload.data.transaction_limits;
  const usdLimit = userInfo.Transaction_limit.find(
    (limit) => limit.currency === "USD"
  );
  return usdLimit;
};
