import { Box, CircularProgress } from '@mui/material';
import React from 'react'

const Loader = ({color}) => {
  return (
    <Box display={"flex"} justifyContent={"center"}>
      
      <CircularProgress color="brand" />
    </Box>
  );
}

export default Loader