import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  Grid,
  // IconButton,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CroboContainer from "../../components/croboContainer";
import { transactionById } from "../../reducers/transactionReducer";
import { amountRoundOff } from "../../helper/helper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Loader from "../../components/loader";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const steps = [
  "Transaction Initiated",
  "Transaction In Process",
  "Transaction Success",
];

const CopyElement = ({ value }) => {
  // const [copied, setCopied] = useState(false);
  // const handleCopy = () => {
  //   navigator?.clipboard?.writeText(value);
  //   setCopied(true);
  //   setTimeout(() => setCopied(false), 1000); // Show "Copied" for 2 seconds
  // };

  return (
    <>
      {false ? (
        <Typography variant="body2" color={"green"} sx={{ marginLeft: 1 }}>
          COPIED
        </Typography>
      ) : (
        <Typography display={"flex"} alignItems={"center"}>
          {value}

          {/* <IconButton
            onClick={handleCopy}
            size="small"
            sx={{ marginLeft: 1, p: 0 }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton> */}
        </Typography>
      )}
    </>
  );
};

const TransactionDetails = () => {
  let { transid } = useParams();
  const { transaction, loading } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(transactionById(transid));
  }, [dispatch, transid]);

  // const isStepFailed = (step) => {
  //   return false;
  // };

  const transactionStatus = () => {
    if (transaction?.status === 2) {
      return {
        color: "red",
        status: true,
      };
    }
    if (
      (transaction?.status === 1 && transaction?.receiveInBank === 0) ||
      transaction?.status === 0
    ) {
      return {
        color: "yellow",
        status: false,
      };
    }
    return {
      color: "green",
      status: false,
    };
  };

  return (
    <CroboContainer>
      {loading ? (
        <Box py={3}>
          <Loader />
        </Box>
      ) : (
        <Container>
          <Box marginY={2}>
            <Accordion sx={{ bgcolor: "grey.200" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="recipientDetails"
                id="recipientDetails"
              >
                <Typography sx={{ fontSize: 20, fontWeight: 900 }}>
                  Transaction Status
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Divider />
                <Stepper
                  sx={{ marginY: 2 }}
                  activeStep={transaction?.transactionStep - 1}
                  orientation="vertical"
                >
                  {steps.map((label, index) => {
                    const labelProps = {};
                    if (transactionStatus().status && index === 2) {
                      labelProps.optional = (
                        <Typography variant="caption" color="error">
                          {/* Alert message */}
                        </Typography>
                      );

                      labelProps.error = true;
                    }

                    return (
                      <Step key={label}>
                        <StepLabel {...labelProps} StepIconComponent={StepIcon}>
                          <Typography variant="body1">
                            {transaction?.message[`STEP_${index + 1}`]}
                          </Typography>
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box paddingBottom={2} mb={10}>
            <Accordion sx={{ bgcolor: "grey.200", marginY: 1 }}>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="transactionDetails"
                id="transactionDetails"
              >
                <Typography sx={{ fontSize: 20, fontWeight: 900 }}>
                  Transaction Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Divider />
                <Grid paddingY={2}>
                  <Grid item display={"flex"} justifyContent={"space-between"}>
                    <Typography>Transfer Amount</Typography>
                    <Typography>
                      ${amountRoundOff(transaction?.base_sub_total)}
                    </Typography>
                  </Grid>
                  <Grid item display={"flex"} justifyContent={"space-between"}>
                    <Typography>Exchange Rate</Typography>
                    <Typography>
                      $1 = ₹{amountRoundOff(transaction?.getbit_exchange_rate)}
                    </Typography>
                  </Grid>
                  <Grid item display={"flex"} justifyContent={"space-between"}>
                    <Typography>Our fees</Typography>
                    <Typography>$0</Typography>
                  </Grid>
                  <Grid item display={"flex"} justifyContent={"space-between"}>
                    <Typography>Recipient will receive</Typography>
                    <Typography>
                      ₹{amountRoundOff(transaction?.base_total_amount)}
                    </Typography>
                  </Grid>
                  {transaction?.transactional_time && (
                    <Grid
                      item
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Typography>Date & Time</Typography>
                      <Typography>{transaction?.transactional_time}</Typography>
                    </Grid>
                  )}
                  {transaction?.utr_no && (
                    <Grid
                      item
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"} // Align items vertically centered
                    >
                      <Typography>UTR No</Typography>
                      <CopyElement value={transaction?.utr_no} />
                    </Grid>
                  )}

                  <Grid
                    item
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"} // Align items vertically centered
                  >
                    <Typography>Transaction Id</Typography>
                    <CopyElement value={transaction?.trans_id} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ bgcolor: "grey.200" }}>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="recipientDetails"
                id="recipientDetails"
              >
                <Typography sx={{ fontSize: 20, fontWeight: 900 }}>
                  Recipient Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Divider />
                <Grid
                  display={"flex"}
                  justifyContent={"space-between"}
                  paddingTop={2}
                >
                  <Typography>Account Holder</Typography>
                  <Typography>{transaction?.bankName}</Typography>
                </Grid>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Typography>Account Number</Typography>
                  <Typography>{transaction?.bankAccountNumber}</Typography>
                </Grid>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Typography>IFSC code</Typography>
                  <Typography>{transaction?.bankIFSC}</Typography>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Container>
      )}
    </CroboContainer>
  );
};

export default TransactionDetails;
