import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { analytics, handleError } from "../helper/helper";

// export const backend = "https://pplunar.crobo.money";
export const backend = "https://lunar.getbitmoneyapp.com";
// export const backend = "https://stage.getbitmoneyapp.com";
export const apiRoutes = {
  remitIdentityVerification: `${backend}/users/remitIdentityVerification`,
  remitStatusUpdate: `${backend}/users/remitstatusupdate/a`,
  auth: `${backend}/auth`,
  register: `${backend}/auth/register`,
  profile: `${backend}/auth/profile`,
  verifyPin: `${backend}/auth/pin/verify`,
  setPin: `${backend}/auth/pin/set`,
  redeem: `${backend}/users/redeem`,
  fetchFrequentContact: `${backend}/users/fetchFrequentContact`,
  fetchUsers: `${backend}/users/V2/fetch`,
  redeemHistory: `${backend}/users/redeem-history`,
  referralHistory: `${backend}/users/referee-history`,
  resendGcCode: `${backend}/users/sendgccode`,
  getRecipients: `${backend}/users/recipients`,
  recipientsAction: `${backend}/users/recipient`,
  subscribeUser: `${backend}/waitlist`,
  preRegister: `${backend}/users/v2/preRegister`,
};

const status = {
  loading: "loading",
  succeeded: "succeeded",
  failed: "failed",
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    phone: localStorage.getItem("phone") ? localStorage.getItem("phone") : null,
    profile: localStorage.getItem("profile")
      ? JSON.parse(localStorage.getItem("profile"))
      : null,
    loading: false,
    remitLoading: false,
    authToken: localStorage.getItem("authToken")
      ? localStorage.getItem("authToken")
      : null,
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
    frequentContacts: [],
    kyc: 0,
    bank: 0,
    bankVerification: 0,
    custom_message: "",
    showDocumentsButton: false,
    bankDetails: null,
    guid: localStorage.getItem("guid") ? localStorage.getItem("guid") : null,
    customMessage: "",
    redeemHistory: [],
    fetchUser: localStorage.getItem("fetchUser")
      ? JSON.parse(localStorage.getItem("fetchUser"))
      : null,
    referralHistory: [],
    error: null,
    pinVerified: false,
    status: "idle",
    statusCode: 0,
    documentUploadUrl: null,
    recipients: [],
    isNewUser: false,
    pin: true,
  },
  reducers: {
    logout: (state) => {
      state.userInfo = null;
      state.authToken = null;
      state.token = null;
      state.profile = null;
      localStorage.clear();
    },
    markPinDisable: (state) => {
      state.pinVerified = false;
    },
    clearMessage: (state) => {
      state.customMessage = "";
      state.error = null;
    },
    clearFetchUser: (state) => {
      state.fetchUser = null;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setRemitLoaded: (state, action) => {
      state.remitLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.data.token.access_token;
        state.isNewUser = action.payload.data.isNewUser;
        state.pin = action.payload.data.pin === 1 ? true : false;
        state.error = null;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = state.fulfilled;
        state.loading = false;
        state.profile = action.payload.data.data.user_profile;
        state.guid = action.payload.data.data.user_profile.external_guid;
        state.error = null;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.loading = false;
        state.errorCode = action.payload.code;
        state.error = action.error.message;
      })
      .addCase(verifyPin.pending, (state, action) => {
        state.status = status.pending;
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyPin.rejected, (state, action) => {
        state.status = status.failed;
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(verifyPin.fulfilled, (state, action) => {
        state.status = status.succeeded;
        state.loading = false;
        state.pinVerified = true;
        state.error = null;
      })
      .addCase(pinSet.pending, (state, action) => {
        state.status = status.pending;
        state.loading = true;
        state.error = null;
      })
      .addCase(pinSet.rejected, (state, action) => {
        state.status = status.failed;
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(pinSet.fulfilled, (state, action) => {
        state.status = status.succeeded;
        state.loading = false;
        state.error = null;
      })
      .addCase(redeem.pending, (state) => {
        state.loading = true;
      })
      .addCase(redeem.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(redeem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(frequentContact.pending, (state) => {
        state.loading = true;
      })
      .addCase(frequentContact.fulfilled, (state, action) => {
        state.loading = false;
        state.frequentContacts = action.payload.data.data;
        state.error = null;
      })
      .addCase(frequentContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(remitStatusUpdate.pending, (state, action) => {
        state.loading = false;
      })
      .addCase(remitStatusUpdate.fulfilled, (state, action) => {
        state.remitLoading = true;
        state.kyc = action.payload.kyc;
        state.bank = action.payload.bank;
        state.bankVerification = action.payload.bank_verification;
        state.customMessage = action.payload.customer_message;
        state.showDocumentsButton =
          action.payload.additional_documents_required;
        state.bankDetails = action.payload.bank_details;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchUser = action.payload.data;
        state.error = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRedeemHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRedeemHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.redeemHistory = action.payload.data.data;
        state.error = null;
      })
      .addCase(getRedeemHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getReferralHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReferralHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.referralHistory = action.payload.data.data;
        state.error = null;
      })
      .addCase(getReferralHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(remitDocumentUpload.pending, (state, action) => {
        state.loading = true;
        state.remitLoading = true
      })
      .addCase(remitDocumentUpload.fulfilled, (state, action) => {
        state.loading = false;
        state.remitLoading = false
        state.documentUploadUrl = action.payload.inquiry_id;
        state.error = null;
        // if (action.payload.kyc_url)
        //   window.open(action.payload.kyc_url, "_blank");
      })
      .addCase(remitDocumentUpload.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getRecipients.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRecipients.fulfilled, (state, action) => {
        state.loading = false;
        state.recipients = action.payload.data;
      })
      .addCase(getRecipients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(subscribeUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(subscribeUser.fulfilled, (state, action) => {
        state.loading = false;
        state.customMessage = action.payload.message;
      })
      .addCase(subscribeUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export const login = createAsyncThunk(
  "user/auth",
  async (param, { dispatch, rejectWithValue }) => {
    try {
      let config = {
        method: "post",
        url: apiRoutes.auth,
        headers: { accessToken: param.authToken },
      };
      const payload = await axios.request(config);
      localStorage.setItem("authToken", param.authToken);
      localStorage.setItem("token", payload.data.token.access_token);
      localStorage.setItem("phone", `+1${param.phone}`);
      dispatch(getProfile());
      return payload;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const getProfile = createAsyncThunk(
  "user/profile",
  async (param, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "get",
        url: apiRoutes.profile,
        headers: { "x-auth-token": token },
      };
      const payload = await axios.request(config);
      const profile = payload.data.data.user_profile;
      localStorage.setItem("profile", JSON.stringify(profile));
      localStorage.setItem(
        "guid",
        payload.data.data.user_profile.external_guid
      );
      analytics.identify(profile.user_id, {
        name: profile.unverified_user_name,
        email: profile.unverified_email,
        phone: localStorage.getItem("phone")
          ? localStorage.getItem("phone")
          : null,
      });
      window.clarity(
        "identify",
        `${profile.user_id}`,
        profile.unverified_email,
        "",
        profile.unverified_user_name
      );
      window.clarity(
        "set",
        "guid",
        profile?.external_guid
      );
      return payload;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const verifyPin = createAsyncThunk(
  "user/pin/verify",
  async (pin, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "post",
        url: apiRoutes.verifyPin,
        headers: { "x-auth-token": token },
        data: {
          pin,
        },
      };
      const payload = await axios.request(config);
      return payload;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const pinSet = createAsyncThunk(
  "user/pin/set",
  async (pin, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "post",
        url: apiRoutes.setPin,
        headers: { "x-auth-token": token },
        data: {
          pin,
        },
      };
      const payload = await axios.request(config);
      return payload;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const redeem = createAsyncThunk(
  "users/redeem",
  async (params, { dispatch, rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "post",
        url: apiRoutes.redeem,
        headers: { "x-auth-token": token },
        data: params,
      };
      const payload = await axios.request(config);
      params.action();
      await dispatch(getProfile());
      return payload;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const frequentContact = createAsyncThunk(
  "user/frequentContact",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "get",
        url: apiRoutes.fetchFrequentContact,
        headers: { "x-auth-token": token },
      };
      const payload = await axios.request(config);
      return payload;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "user/fetchUsers",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "post",
        url: apiRoutes.fetchUsers,
        data: params,
        headers: { "x-auth-token": token },
      };
      const payload = await axios.request(config);
      localStorage.setItem("fetchUser", JSON.stringify(payload.data));
      return payload;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const getRedeemHistory = createAsyncThunk(
  "users/redeem-history",
  async ({ page, pageSize, filter }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const url = `${apiRoutes.redeemHistory}?page=${page}&pageSize=${pageSize}&filter=${filter}`;
      let config = {
        method: "get",
        url: url,
        headers: { "x-auth-token": token },
      };
      const payload = await axios.request(config);
      return payload;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const getReferralHistory = createAsyncThunk(
  "users/referee-history",
  async ({ page, pageSize, filter }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      let config = {
        method: "get",
        url: `${apiRoutes.referralHistory}?page=${page}&pageSize=${pageSize}&filter=${filter}`,
        headers: { "x-auth-token": token },
      };
      const payload = await axios.request(config);
      return payload;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const remitStatusUpdate = createAsyncThunk(
  "users/remitstatusupdate/a",
  async ({ token }, { rejectWithValue }) => {
    try {
      let config = {
        method: "get",
        url: `${backend}/users/remitStatusUpdate/a`,
        headers: {
          "x-auth-token": token,
        },
      };
      const { data } = await axios.request(config);
      return data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const remitDocumentUpload = createAsyncThunk(
  "users/remitIdentityVerification",
  async ({ token, type }, { rejectWithValue }) => {
    try {
      let config = {
        method: "post",
        url: `${backend}/users/remitIdentityVerification`,
        headers: {
          "x-auth-token": token,
        },
        data: {
          type
        },
      };
      const { data } = await axios.request(config);
      return data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const resendGcCode = createAsyncThunk(
  "users/sendgccode",
  async (params, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const url = apiRoutes.resendGcCode;
      let config = {
        method: "post",
        url: url,
        headers: { "x-auth-token": token },
        data: params,
      };
      const payload = await axios.request(config);
      return payload;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);
export const subscribeUser = createAsyncThunk(
  "subscription/subscribeUser",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "post",
        url: apiRoutes.subscribeUser,
        data: { email: params },
        headers: { "x-auth-token": token },
      };
      const payload = await axios.request(config);
      return payload.data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const getRecipients = createAsyncThunk(
  "users/getRecipients",
  async (params, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const url = apiRoutes.getRecipients;
      let config = {
        method: "get",
        url: url,
        headers: { "x-auth-token": token },
      };
      const payload = await axios.request(config);
      return payload.data;
    } catch (error) {
      return rejectWithValue(await handleError(error));
    }
  }
);

export const updateRecipient = async (params) => {
  try {
    const token = localStorage.getItem("token");
    const url = `${apiRoutes.recipientsAction}/${params.id}`;
    let config = {
      method: "put",
      url: url,
      headers: { "x-auth-token": token },
      data: params,
    };
    const payload = await axios.request(config);
    return payload.data;
  } catch (error) {
    await handleError(error);
  }
};

export const register = async (params) => {
  try {
    const token = localStorage.getItem("token");
    let config = {
      method: "post",
      url: apiRoutes.register,
      headers: { "x-auth-token": token },
      data: params,
    };
    const payload = await axios.request(config);
    return payload.data;
  } catch (error) {
    await handleError(error);
  }
};

export const {
  logout,
  markPinDisable,
  clearMessage,
  clearFetchUser,
  setLoader,
  setRemitLoaded
} = userSlice.actions;
export default userSlice.reducer;
