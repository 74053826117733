import {
    Container,
    Button,
    Box,
    Grid,
    Typography,
    IconButton,
} from "@mui/material";
import referImage from "../../assets/refer_and_earn.png";
import CroboContainer from "../../components/croboContainer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { getProfile, redeem } from "../../reducers/userReducer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WhatsappContact from "../../components/whatsappTab";
import { useNavigate } from 'react-router-dom';
import { RWebShare } from "react-web-share";

const shareMessage = (referral_code, amount = 10) => {
    return `Crobo ensures instant, no-fee transfers from the US to India. Sign up with Crobo: https://crobo.money/send-signup via my referral link and earn $${amount} instantly by using my Referral Code during signup: ${referral_code}`
}

const Referrals = () => {
    const userStore = useSelector((state) => {
        return state.userAuth?.profile
    });
    const envStore = useSelector((state) => {
        return state.resource?.envList;
    });
    const { error } = useSelector(
        (state) => state.userAuth
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);
    const notify = () => toast.success("Referral code copied!");
    const notifyRedeemSuccess = () => toast.success("Redeem successfully generated, please check your email!");
    const navigate = useNavigate();
    const [isRedeemAvailable, setRedeemAvailable] = useState(userStore?.balance?.REF <= 0);
    return (
        <>
            <CroboContainer>
                <Container>
                    <Grid justifyContent="center" minWidth={360} container marginTop={1} marginBottom={2}>
                        <img src={referImage} width={'60%'} alt="img" />
                        <Typography>
                            Share your referral code to earn.
                        </Typography>
                        <Container sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: 'lightgray',
                            borderRadius: '12px',
                            height: '72px',
                            marginTop: 4
                        }} bgcolor={'gray'}>
                            <Typography>
                                Your Rewards
                            </Typography>
                            <Box>
                                {parseFloat(userStore?.balance?.REF || 0).toFixed(2)}
                                <Button sx={{
                                    marginLeft: 1,
                                    borderRadius: 4,
                                    bgcolor: 'brand.main',
                                    "&:hover": {
                                        backgroundColor: "brand.dark",
                                    },
                                }} variant='contained' color="primary"
                                    onClick={() => {
                                        setRedeemAvailable(true)
                                        dispatch(redeem({
                                            data: {
                                                amount: userStore?.balance?.REF
                                            },
                                            action: notifyRedeemSuccess
                                        }))
                                    }}
                                    disabled={isRedeemAvailable}
                                >
                                    Redeem
                                </Button>
                            </Box>
                        </Container>
                        <Box display={"flex"} justifyContent={"center"}>
                            {error && (
                            <Typography textAlign={"center"} sx={{ color: "red" }}>
                                {error}
                            </Typography>
                            )}
                        </Box>
                        <Button onClick={() => {
                            navigate('/referrals/redeem-history');
                        }} variant="text" sx={{
                            marginLeft: 'auto',
                            textDecoration: 'underline'
                        }}>
                            View Redeem History
                        </Button>
                        <div style={{
                            padding: 0,
                            width: '100%',
                            marginTop: '24px'
                        }}>
                            <Typography>
                                Referral Code
                            </Typography>
                        </div>
                        <Container sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: 'lightgray',
                            borderRadius: '12px',
                            height: '72px',
                            marginTop: '4px',
                        }} bgcolor={'gray'}>
                            <Typography>
                                {userStore?.referral_code}
                            </Typography>
                            <IconButton onClick={() => {
                                navigator.clipboard.writeText(userStore?.referral_code)
                                notify()
                            }} aria-label="copy">
                                <ContentCopyIcon />
                            </IconButton>
                        </Container>
                        <Button onClick={() => {
                            navigate('/referrals/referral-history');
                        }} variant="text" sx={{
                            marginLeft: 'auto',
                            textDecoration: 'underline'
                        }}>
                            View Referral History
                        </Button>
                        <div style={{
                            padding: 0,
                            width: '100%',
                            marginTop: '24px'
                        }}>
                            <Typography>
                                After the first transaction, both you and your referee receive a ${envStore?.REFERRER_AMOUNT || 10} Amazon Gift Card
                            </Typography>
                        </div>

                        <Button variant="text" style={{
                            textDecoration: 'underline'
                        }}
                            onClick={() => {
                                navigate('/referrals/refer-faq');
                            }}
                        >
                            Know More
                        </Button>
                        <Container sx={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            marginTop: 2
                        }}>
                            <WhatsappContact message={shareMessage(userStore?.referral_code, envStore?.REFERRER_AMOUNT)} number={'1023'}>
                                Invite On Whatsapp
                            </WhatsappContact>
                            
                            <RWebShare
                                data={{
                                    text: shareMessage(userStore?.referral_code),
                                    url: userStore?.referral_code,
                                    title: "Referral Code",
                                }}
                                disableNative
                                sites={['facebook', 'linkedin', 'telegram', 'reddit', 'mail', 'twitter']}
                            >
                                <Button variant="contained" sx={{
                                    borderRadius: 12,
                                    bgcolor: 'brand.main',
                                    color: 'brand',
                                    "&:hover": {
                                        backgroundColor: "brand.dark",
                                    },
                                }}>
                                    <ShareIcon />
                                </Button>   
                            </RWebShare>
                        </Container>
                    </Grid>
                </Container>
                <ToastContainer
                    hideProgressBar
                    position="top-center"
                />
            </CroboContainer>
        </>
    );
}

export default Referrals;