import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { useDispatch, useSelector } from "react-redux";
import { getBanks } from "../../reducers/resourceReducer";
import CodeIcon from "@mui/icons-material/Code";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {
  preRegister,
  resetPreregister,
} from "../../reducers/preRegisterReducer";
import { useNavigate, useParams } from "react-router-dom";
import { analytics } from "../../helper/helper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RecipientDetails = () => {
  const [bankScreen, setBankScreen] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [confirmBankAccountNumber, setConfirmBankAccountNumber] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { bankList } = useSelector((state) => state.resource);
  const { id } = useParams();
  const navigate = useNavigate();
  const { preRegisterDetails } = useSelector((state) => state.preRegister);

  useEffect(() => {
    dispatch(resetPreregister());
    if (preRegisterDetails) {
      navigate(`/transaction/create/${id}`);
    }
    dispatch(getBanks());
  }, [dispatch, preRegisterDetails, id, navigate]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleRecipient = () => {
    if (
      !bankName ||
      !bankAccountNumber ||
      !confirmBankAccountNumber ||
      !IFSC ||
      !name
    ) {
      setError("All fields are compulsory");
      analytics.track("Recipient Bank Details Submission Failed", {
        reason: "Missing Fields",
      }); // Track failure due to missing fields
      return;
    }
    if (bankAccountNumber !== confirmBankAccountNumber) {
      setError(
        "Bank Account Number and Confirm Bank Account Number do not match"
      );
      analytics.track("Recipient Bank Details Submission Failed", {
        reason: "Account Number Mismatch",
      }); // Track failure due to mismatch
      return;
    }

    if (!bankName) {
      setError("Please Select Bank");
      analytics.track("Recipient Bank Details Submission Failed", {
        reason: "No Bank Selected",
      }); // Track failure due to missing bank
      return;
    }
    setError(null);

    analytics.track("Recipient Bank Details Submitted", {
      name,
      email,
      bankName,
      bankAccountNumber,
      IFSC,
    }); // Track successful bank details submission

    dispatch(
      preRegister({
        phone_number: id,
        user_name: name,
        client_id: "APIGetBitRemitWeb",
        country_code: "91",
        email: email,
        bank_details: {
          bank_account_number: bankAccountNumber,
          bank_name: bankName,
          ifsc_code: IFSC,
          account_holder_name: name,
        },
      })
    );
  };

  const handleProceedToBankDetails = () => {
    if (!name) {
      setError("Name is required");
      analytics.track("Recipient Name Submission Failed", {
        reason: "Missing Name",
      }); // Track failure due to missing name
      return;
    }
    if (!email) {
      setError("Email is required");
      analytics.track("Recipient Email Submission Failed", {
        reason: "Missing Email",
      }); // Track failure due to missing email
      return;
    }
    if (!validateEmail(email)) {
      setError("Invalid email address");
      analytics.track("Recipient Email Submission Failed", {
        reason: "Invalid Email",
      }); // Track failure due to invalid email
      return;
    }
    setError(null);
    analytics.track("Recipient Details Proceeded to Bank Details", {
      name,
      email,
    }); // Track proceeding to bank details
    setBankScreen(true);
  };

  return (
    <CroboContainer>
      <Container>
        {bankScreen ? (
          <Box
            paddingTop={4}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography sx={{ paddingBottom: 2, width: "100%" }} variant="h5">
              Enter Recipient Bank Details
            </Typography>
            {error && (
              <Typography color={"red"} textAlign={"center"}>
                {error}
              </Typography>
            )}
            <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="bankNameLabel"
                id="bankName"
                displayEmpty
                sx={{ width: "100%" }}
                value={bankName}
                onChange={(e) => {
                  setBankName(e.target.value);
                }}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (!selected) {
                    return <Typography>Bank Name</Typography>;
                  }

                  return selected;
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  <Typography>Bank Name</Typography>
                </MenuItem>
                {bankList.map((b) => (
                  <MenuItem key={b} value={b}>
                    {b}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <OutlinedInput
              placeholder="Enter Account Number"
              value={bankAccountNumber}
              type="password"
              sx={{ marginTop: 2, width: "100%" }}
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              }
              onChange={(e) => setBankAccountNumber(e.target.value)}
            />
            <OutlinedInput
              placeholder="Confirm Account Number"
              value={confirmBankAccountNumber}
              sx={{ marginTop: 2, width: "100%" }}
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              }
              onChange={(e) => setConfirmBankAccountNumber(e.target.value)}
            />

            <OutlinedInput
              placeholder="Account Holder Name"
              value={name}
              sx={{ marginTop: 2, width: "100%" }}
              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              }
              onChange={(e) => setName(e.target.value)}
            />
            <OutlinedInput
              placeholder="IFSC Code"
              value={IFSC}
              sx={{ marginTop: 2, width: "100%" }}
              startAdornment={
                <InputAdornment position="start">
                  <CodeIcon />
                </InputAdornment>
              }
              onChange={(e) => setIFSC(e.target.value)}
            />
            <Button
              color="brand"
              variant="contained"
              sx={{ marginTop: 2, width: "100%" }}
              size="large"
              onClick={handleRecipient}
            >
              <Typography variant="h6" color={"black"}>
                Proceed
              </Typography>
            </Button>
          </Box>
        ) : (
          <Box
            paddingTop={4}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography sx={{ paddingBottom: 0, width: "100%" }} variant="h5">
              Enter Recipient Details
            </Typography>
            {error && (
              <Typography color={"red"} textAlign={"center"}>
                {error}
              </Typography>
            )}
            <OutlinedInput
              placeholder="Enter Name"
              value={name}
              sx={{ marginTop: 2, width: "100%" }}
              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              }
              onChange={(e) => setName(e.target.value)}
            />
            <OutlinedInput
              placeholder="Enter Email"
              value={email}
              sx={{ marginTop: 2, width: "100%" }}
              startAdornment={
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              }
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              color="brand"
              variant="contained"
              onClick={handleProceedToBankDetails}
              sx={{ marginTop: 2, width: "100%" }}
              size="large"
            >
              <Typography variant="h6" color={"black"}>
                Proceed
              </Typography>
            </Button>
          </Box>
        )}
      </Container>
    </CroboContainer>
  );
};

export default RecipientDetails;
