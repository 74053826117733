import React, { useState } from "react";
import { Container, Button, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { pinSet } from "../../reducers/userReducer";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import CroboContainer from "../../components/croboContainer";
import OTPInput from "react-otp-input";

const EnterPin = ({ pin, setPin, setPinCheck }) => {
  return (
    <Container>
      <Box paddingTop={10} paddingBottom={4}>
        <Typography textAlign={"center"} variant={"h5"}>
          SET PIN
        </Typography>
      </Box>

      <Box>
        <OTPInput
          value={pin}
          label="PIN"
          inputType="number"
          shouldAutoFocus="true"
          onChange={setPin}
          numInputs={4}
          renderSeparator={<span style={{ margin: 5 }}></span>}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                width: "100%",
                height: "40px",
                fontSize: 18,
                textAlign: "center",
                fontFamily: "Arial",
              }}
            />
          )}
        />
      </Box>

      <Box paddingY={2}>
        <Button
          color="brand"
          onClick={() => setPinCheck(true)}
          variant="contained"
          fullWidth
          size="large"
        >
          {/* <CircularProgress color="brand" /> */}

          <Typography variant="h6">Proceed</Typography>
        </Button>
      </Box>
    </Container>
  );
};

const ConfirmPin = ({ confirmPin, setConfirmPin, resetPin,loading}) => {
  return (
    <Container>
      <Box paddingTop={10} paddingBottom={4}>
        <Typography textAlign={"center"} variant={"h5"}>
          Confirm PIN
        </Typography>
      </Box>

      <Box>
        <OTPInput
          value={confirmPin}
          label="PIN"
          inputType="number"
          shouldAutoFocus="true"
          onChange={setConfirmPin}
          numInputs={4}
          renderSeparator={<span style={{ margin: 5 }}></span>}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                width: "100%",
                height: "40px",
                fontSize: 18,
                textAlign: "center",
                fontFamily: "Arial",
              }}
            />
          )}
        />
      </Box>

      <Box paddingY={2}>
        {loading ? (
          <Loader />
        ) : (
          <Button
            color="brand"
            onClick={resetPin}
            variant="contained"
            fullWidth
            size="large"
          >
            <Typography variant="h6">Confirm</Typography>
          </Button>
        )}
      </Box>
    </Container>
  );
};

const SetPin = () => {
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [pinCheck, setPinCheck] = useState(false);
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.userAuth);
  const navigate = useNavigate()
  const resetPin = () => {
    if (pin === confirmPin) {
      dispatch(pinSet(pin))
      navigate('/pin')
    } else {
      setPinCheck(false);
    }
  };
  return (
    <CroboContainer>
      {!pinCheck ? (
        <EnterPin pin={pin} setPin={setPin} setPinCheck={setPinCheck} />
      ) : (
        <ConfirmPin
          confirmPin={confirmPin}
          setConfirmPin={setConfirmPin}
          resetPin={resetPin}
          loading={loading}
        />
      )}
    </CroboContainer>
  );
};

export default SetPin;
