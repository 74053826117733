import React, { useState, useEffect } from "react";
import {
  auth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  firebaseErrorHandler,
} from "../service/firebase";
import {
  Container,
  Button,
  Box,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { login } from "../reducers/userReducer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ErrorComponent from "../components/errorComponent";
import Loader from "../components/loader";
import { analytics } from "../helper/helper";
import CroboContainer from "../components/croboContainer";
import OTPInput from "react-otp-input";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [cooldown, setCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(60); // Set cooldown timer to 60 seconds
  const [isResendDisabled, setIsResendDisabled] = useState(true); // Disable the resend button initially
  const location = useLocation();
  const { profile, pin, isNewUser } = useSelector((state) => state.userAuth);

  useEffect(() => {
    setPhone("");
    setOtp("");
    setOtpSent("");
    setConfirmationResult("");
    setError("");
    if (profile) {
      if (isNewUser) {
        navigate("/new");
      } else if (!pin) {
        navigate("/pin/set");
      } else if (pin) {
        navigate("/pin");
      }
    }
  }, [navigate, profile, pin, isNewUser]);

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      auth.appVerificationDisabledForTesting = true;
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // recaptcha solved
          },
        }
      );
      window.recaptchaVerifier.render().catch((error) => {
        console.error("Recaptcha render failed:", error);
      });
    }
  }, []);

  // Countdown logic for the cooldown timer
  useEffect(() => {
    let interval;
    if (cooldown) {
      interval = setInterval(() => {
        setCooldownTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(interval);
            setCooldown(false);
            setIsResendDisabled(false); // Enable the resend button after cooldown
            return 60;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [cooldown]);

  const handleSignInWithPhone = async () => {
    try {
      setLoader(true);
      analytics.track("OTP Requested", { phone }); // Track OTP request
      if (cooldown) {
        setError("Please wait a moment before retrying.");
        setLoader(false);
        return;
      }
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        `+1${phone}`,
        appVerifier
      );
      setConfirmationResult(confirmationResult);

      setOtpSent(true);
      setLoader(false);
      setIsResendDisabled(true); // Disable the resend button
      setCooldown(true); // Start cooldown timer

      analytics.track("OTP Sent", { phone }); // Track OTP sent
    } catch (error) {
      const errorMessage = firebaseErrorHandler(error.code);
      setError(errorMessage);
      setLoader(false);
      analytics.track("OTP Request Failed", { phone, error: errorMessage }); // Track OTP request failure

      if (error.code === "auth/too-many-requests") {
        setCooldown(true);
        setTimeout(() => setCooldown(false), 60000); // Set cooldown for 60 seconds
      }
    }
  };

  const handleVerifyOtp = async () => {
    setLoader(true);
    analytics.track("OTP Verification Attempt", { phone, otp }); // Track OTP verification attempt
    if (confirmationResult) {
      try {
        const result = await confirmationResult.confirm(otp);

        analytics.identify(`+1${phone}`, {});
        dispatch(login({ phone, authToken: result.user.accessToken }));
        analytics.track("Login Success", { phone }); // Track successful login
      } catch (error) {
        const errorMessage = firebaseErrorHandler(error.code);
        setError(errorMessage);
        setLoader(false);
        analytics.track("OTP Verification Failed", {
          phone,
          error: errorMessage,
        }); // Track OTP verification failure
      }
    }
  };

  const handleResendOtp = () => {
    analytics.track("OTP Resent", { phone }); // Track OTP resent
    setIsResendDisabled(true); // Disable resend button immediately
    handleSignInWithPhone(); // Resend OTP
  };

  return (
    <CroboContainer>
      <Container>
        <Box paddingTop={10} paddingBottom={4}>
          {location.pathname === "/login" ? (
            <>
              <Typography textAlign={"center"} variant="h5">
                Login to Crobo
              </Typography>
              <Typography textAlign={"center"} variant="body2">
                Login to your Crobo account to securely <br /> send money to
                your family in India.
              </Typography>
            </>
          ) : (
            <>
              <Typography textAlign={"center"} variant="h5">
                Sign Up for Crobo
              </Typography>
              <Typography textAlign={"center"} variant="body2">
                Create an account to securely <br /> send money to your family
                in India
              </Typography>
            </>
          )}
        </Box>

        <Box>
          <OutlinedInput
            placeholder="Phone Number"
            value={phone}
            type="number"
            disabled={otpSent}
            onChange={(e) => {
              const p = e.target.value;
              setPhone(p.toString());
              if (p.toString().length > 10) {
                setError("Invalid Phone Number");
              } else {
                setError(null);
              }
            }}
            startAdornment={
              <InputAdornment position="start">
                <img
                  src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                  height={30}
                  width={40}
                  alt="+1"
                />
                <Typography textAlign={"center"} paddingX={1}>
                  (+1)
                </Typography>
              </InputAdornment>
            }
            fullWidth
            inputProps={{
              maxLength: 10,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Box>
        {error && <ErrorComponent error={error} />}

        {!otpSent && (
          <Box paddingY={2}>
            {loader ? (
              <Loader />
            ) : (
              <Button
                onClick={handleSignInWithPhone}
                id="sign-in-button"
                variant="contained"
                color="brand"
                fullWidth
                size="large"
                disabled={phone.length !== 10 || cooldown}
              >
                <Typography variant="h6">Proceed</Typography>
              </Button>
            )}
          </Box>
        )}

        {otpSent && (
          <Box paddingY={2}>
            <OTPInput
              value={otp}
              label="OTP"
              inputType="number"
              shouldAutoFocus="true"
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span style={{ margin: 5 }}></span>}
              renderInput={(props) => (
                <input
                  {...props}
                  style={{
                    width: "100%",
                    height: "40px",
                    fontSize: 18,
                    textAlign: "center",
                    fontFamily: "Arial",
                  }}
                />
              )}
            />
            <Box paddingY={2}>
              {loader ? (
                <Loader />
              ) : (
                <Button
                  variant="contained"
                  color="brand"
                  onClick={handleVerifyOtp}
                  fullWidth
                  disabled={otp.length !== 6}
                  margin="normal"
                  size="large"
                >
                  Verify OTP
                </Button>
              )}
            </Box>
            <Typography textAlign="center">
              {cooldown
                ? `Resend OTP in ${cooldownTime} seconds`
                : "Didn't receive the OTP?"}
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                onClick={handleResendOtp}
                variant="text"
                disabled={isResendDisabled}
              >
                Resend OTP
              </Button>
            </Box>
          </Box>
        )}
        <Box>
          <Grid marginTop={15}>
            {location.pathname === "/signup" && (
              <Typography variant="body2" textAlign={"center"}>
                By continuing, you agree to our{" "}
                <Link to={"/tnc"}>Terms of Service</Link> and <br /> confirm
                that you have read our{" "}
                <Link to={"/privacy-policy"}>Privacy Policy</Link> to learn{" "}
                <br /> how we collect, use, and share your data.
              </Typography>
            )}
          </Grid>
        </Box>
        <Box>
          {location.pathname === "/login" ? (
            <Typography textAlign={"center"} marginTop={4}>
              Don't have an account?
              <Link to={"/signup"}>
                <Button variant="text">Sign Up</Button>
              </Link>{" "}
            </Typography>
          ) : (
            <Typography textAlign={"center"} marginTop={4}>
              Already have an account?
              <Link to={"/login"}>
                <Button variant="text">Login</Button>
              </Link>
            </Typography>
          )}
        </Box>
      </Container>
    </CroboContainer>
  );
};

export default Login;
