// Auth.js
import React, { useState } from "react";
import { Container, Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";

const ResetPin = ({confirmationResult,error}) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate()
  const handleVerifyOtp = async () => {
    if (confirmationResult) {
      try {
        await confirmationResult.confirm(otp);
        navigate("/pin/set");
      } catch (error) {
        console.error("Error verifying OTP:", error);
      }
    }
  };
  return (
    <Container>
      <Box paddingTop={10} paddingBottom={4}>
        <Typography textAlign={"center"} variant={"h5"}>
          ENTER OTP
        </Typography>
      </Box>

      <Box paddingY={2}>
        <OTPInput
          value={otp}
          label="OTP"
          inputType="number"
          shouldAutoFocus="true"
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span style={{ margin: 5 }}></span>}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                width: "100%",
                height: "40px",
                fontSize: 18,
                textAlign: "center",
                fontFamily: "Arial",
              }}
            />
          )}
        />
        {error && (
          <Typography textAlign={"center"} sx={{ color: "red" }}>
            {error}
          </Typography>
        )}
        <Box paddingY={2}>
          <Button
            variant="contained"
            color="brand"
            onClick={handleVerifyOtp}
            fullWidth
            margin="normal"
            size="large"
          >
            Verify OTP
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPin;
