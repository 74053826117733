import React, { useEffect, useState } from "react";
import CroboContainer from "../components/croboContainer";
import {
  Box,
  Avatar,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Modal,
  Container,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import GroupIcon from "@mui/icons-material/Group";
import NoteIcon from "@mui/icons-material/Note";
import PolicyIcon from "@mui/icons-material/Policy";
import GavelIcon from "@mui/icons-material/Gavel";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useDispatch, useSelector } from "react-redux";
import { apiRoutes, getProfile, remitStatusUpdate, setLoader } from "../reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { processName } from "../helper/helper";
import { logout } from "../reducers/userReducer";
import { personaUrl } from "../constants/apiConstants";
import axios from "axios";
import { runPersonaClient } from "../components/persona";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "500px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Profile = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [amount, setAmount] = useState(1);
  const [purpose, setPurpose] = useState();
  const [SSNStatus, setSSNStatus] = useState(0);
  const [hideSSN, setHideSSN] = useState(false);
  const handleReferAndEarnClick = () => {
    navigate("/referrals");
  };
  const handleManageRecipientsClick = () => {
    navigate("/manage-recipients");
  };
  const handleLogout = () => {
    dispatch(logout);
    navigate("/login");
  };

  const handleManageBankAccount = () => {
    navigate("/manage-bank-account");
  };

  const [userName, setUserName] = useState(null);
  const [transactionLimit, setTransactionLimit] = useState(null);
  const {token} = useSelector((state) => state.userAuth);

  const phoneStore = useSelector((state) => {
    return localStorage.getItem("phone") ? localStorage.getItem("phone") : null;
  });
  const envStore = useSelector((state) => {
    return state.resource?.envList;
  });

  const user = useSelector((state) => state.userAuth);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUser = async () => {
      const phone = localStorage.getItem("phone");
      const token = localStorage.getItem("token");
      let config = {
        method: "post",
        url: apiRoutes.fetchUsers,
        data: {
          client_id: "APIGetBitRemitWeb",
          country_code: "+1",
          phone_number: phone.substring(2),
          user_type: "sender",
        },
        headers: { "x-auth-token": token },
      };
      const payload = await axios.request(config);
      localStorage.setItem("fetchUser", JSON.stringify(payload.data));
      const userInfo = payload.data;
      if (userInfo && userInfo.transaction_limits) {
        const usdLimit = userInfo.transaction_limits.Transaction_limit.find(
          (limit) => limit.currency === "USD"
        );
        setTransactionLimit(usdLimit);
      }
    };
    fetchUser();
    const profile = user.profile
      ? user.profile
      : JSON.parse(localStorage.getItem("profile"));
    if (profile) {
      setUserName(processName(profile?.user_name));
      setSSNStatus(profile.ssn_status);
    }
  }, [user.profile]);

  const onPersonaComplete = async ()=>{
    dispatch(setLoader(true))
    await new Promise(resolve => setTimeout(resolve, 2000)); // 2 seconds delay
    dispatch(remitStatusUpdate({token}));
    dispatch(getProfile());
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission from reloading the page
    dispatch(setLoader(true))

    const token = localStorage.getItem("token");

    // Open a new window immediately to avoid pop-up blockers
    // const newWindow = window.open("", "_blank");

    try {
      let config = {
        method: "post",
        url: apiRoutes.remitIdentityVerification,
        headers: { "x-auth-token": token },
        data: {
          type: "ssn",
        },
      };

      const payload = await axios.request(config);
      handleClose();
      // setHideSSN(true);
      runPersonaClient(payload.data.inquiry_id, onPersonaComplete);
      dispatch(setLoader(false))

      // Navigate the new window to the desired URL
      // newWindow.location.href = `${personaUrl}${payload.data.inquiry_id}`;
    } catch (error) {
      console.error("Error submitting data:", error);
      // Close the window if the request fails
      // if (newWindow) {
      //   newWindow.close();
      // }
    }
  };

  return (
    <>
      <CroboContainer>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          paddingY={4}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ minWidth: "300px" }}
          >
            <Box mx={4}>
              <Avatar
                sx={{
                  backgroundColor: "brand.main",
                  width: 80,
                  height: 80,
                  mb: 2,
                }}
              >
                <Typography variant="h3">{userName?.letters}</Typography>
              </Avatar>
            </Box>
            <Box>
              <Typography variant="h5">{userName?.words}</Typography>
              <Typography variant="body1" color="textSecondary">
                {phoneStore}
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            {transactionLimit && (
              <Box sx={{ minWidth: "300px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    my: 2,
                  }}
                >
                  <Typography variant="body">Daily Limit</Typography>
                  <Typography variant="body">
                    {transactionLimit.Daily} of {transactionLimit.Max_daily}{" "}
                    left
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    my: 2,
                  }}
                >
                  <Typography variant="body">Weekly Limit</Typography>
                  <Typography variant="body">
                    {transactionLimit.Weekly} of {transactionLimit.Max_weekly}{" "}
                    left
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          {!(SSNStatus !== 0 || hideSSN) && (
            <Button onClick={handleOpen}>
              Click here to get enhanced limits
            </Button>
          )}

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Container>
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Start your SSN Verification
                </Typography>
                <Box component="form">
                  {/* First Amount Input Box */}
                  <TextField
                    fullWidth
                    label="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    variant="outlined"
                    margin="normal"
                  />

                  <TextField
                    fullWidth
                    label="Purpose of Transfer"
                    value={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                    variant="outlined"
                    margin="normal"
                  />

                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">Transfer Type</FormLabel>
                    <RadioGroup
                      row
                      aria-label="transfer-type"
                      name="transfer-type"
                      defaultValue="self"
                    >
                      <FormControlLabel
                        value="self"
                        control={<Radio />}
                        label="Self"
                      />
                      <FormControlLabel
                        value="someone-else"
                        control={<Radio />}
                        label="Someone Else"
                      />
                    </RadioGroup>
                  </FormControl>

                  <Button
                    variant="contained"
                    color="brand"
                    size="large"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Proceed
                  </Button>
                </Box>
              </Box>
            </Container>
          </Modal>

          <List
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <ListItem>
                <Button
                  variant="outlined"
                  onClick={handleManageBankAccount}
                  sx={{
                    minWidth: "350px",
                    height: 50,
                    borderRadius: 2,
                    backgroundColor: "whitesmoke",
                    color: "black",
                    borderColor: "#d9dcdd",
                    display: "flex",
                    justifyContent: "flex-start",
                    "&:hover": {
                      backgroundColor: "#d9dcdd",
                      borderColor: "#d9dcdd",
                    },
                  }}
                >
                  <ListItemIcon>
                    <AccountBalanceIcon sx={{ color: "black" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      fontWeight: "bold",
                    }}
                  >
                    Manage Bank Account
                  </Typography>
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  variant="outlined"
                  sx={{
                    minWidth: "350px",

                    height: 50,
                    borderRadius: 3,
                    backgroundColor: "whitesmoke",
                    color: "black",
                    borderColor: "#d9dcdd",
                    display: "flex",
                    justifyContent: "flex-start",
                    "&:hover": {
                      backgroundColor: "#d9dcdd",
                      borderColor: "#d9dcdd",
                    },
                  }}
                  href="mailto:care@getbit.money"
                >
                  <ListItemIcon>
                    <HeadsetMicIcon sx={{ color: "black" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      fontWeight: "bold",
                    }}
                  >
                    Contact Us
                  </Typography>
                </Button>
              </ListItem>
              <ListItem
                sx={{
                  display: envStore?.RECIPIENT_TABLE_FLAG ? "block" : "none",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    minWidth: "350px",

                    height: 50,
                    borderRadius: 3,
                    backgroundColor: "whitesmoke",
                    color: "black",
                    borderColor: "#d9dcdd",
                    display: "flex",
                    justifyContent: "flex-start",
                    "&:hover": {
                      backgroundColor: "#d9dcdd",
                      borderColor: "#d9dcdd",
                    },
                  }}
                  onClick={handleManageRecipientsClick}
                >
                  <ListItemIcon>
                    <GroupIcon sx={{ color: "black" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      fontWeight: "bold",
                    }}
                  >
                    Manage Recipients
                  </Typography>
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  onClick={handleReferAndEarnClick}
                  variant="outlined"
                  sx={{
                    minWidth: "350px",

                    height: 50,
                    borderRadius: 3,
                    backgroundColor: "whitesmoke",
                    color: "black",
                    borderColor: "#d9dcdd",
                    display: "flex",
                    justifyContent: "flex-start",
                    "&:hover": {
                      backgroundColor: "#d9dcdd",
                      borderColor: "#d9dcdd",
                    },
                  }}
                >
                  <ListItemIcon>
                    <NoteIcon sx={{ color: "black" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      fontWeight: "bold",
                    }}
                  >
                    Refer and Earn
                  </Typography>
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  variant="outlined"
                  sx={{
                    minWidth: "350px",

                    height: 50,
                    borderRadius: 3,
                    backgroundColor: "whitesmoke",
                    color: "black",
                    borderColor: "#d9dcdd",
                    display: "flex",
                    justifyContent: "flex-start",
                    "&:hover": {
                      backgroundColor: "#d9dcdd",
                      borderColor: "#d9dcdd",
                    },
                  }}
                  href="https://getbit-termsandconditions.s3.amazonaws.com/Privacy+Policy_GetBit_US_V2_08.23.2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemIcon>
                    <PolicyIcon sx={{ color: "black" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      fontWeight: "bold",
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  variant="outlined"
                  sx={{
                    minWidth: "350px",

                    height: 50,
                    borderRadius: 3,
                    backgroundColor: "whitesmoke",
                    color: "black",
                    borderColor: "#d9dcdd",
                    display: "flex",
                    justifyContent: "flex-start",
                    "&:hover": {
                      backgroundColor: "#d9dcdd",
                      borderColor: "#d9dcdd",
                    },
                  }}
                  href="https://getbit-termsandconditions.s3.amazonaws.com/Terms+of+Use_GetBit_US_V1_08.23.2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemIcon>
                    <GavelIcon sx={{ color: "black" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      fontWeight: "bold",
                    }}
                  >
                    Terms and Conditions
                  </Typography>
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  onClick={() => {
                    handleLogout();
                  }}
                  variant="outlined"
                  sx={{
                    minWidth: "350px",

                    height: 50,
                    borderRadius: 3,
                    backgroundColor: "whitesmoke",
                    color: "black",
                    borderColor: "#d9dcdd",
                    display: "flex",
                    justifyContent: "flex-start",
                    "&:hover": {
                      backgroundColor: "#d9dcdd",
                      borderColor: "#d9dcdd",
                    },
                  }}
                >
                  <ListItemIcon>
                    <ExitToAppIcon sx={{ color: "black" }} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      fontWeight: "bold",
                    }}
                  >
                    Logout
                  </Typography>
                </Button>
              </ListItem>
            </Box>
          </List>
        </Box>
      </CroboContainer>
    </>
  );
};

export default Profile;
