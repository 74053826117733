import React from "react";
import { CssBaseline, Container, Box, Typography } from "@mui/material";
import "../App.css"
import Footer from "../components/footer";
import HomeContent from "../components/homeContent";
import Nav from "../components/nav";

const Home = ()=>{
    return (
      <>
        <CssBaseline />
        <Box sx={{ height: "55px" }}>
          <Nav />
        </Box>
        <Container>
          <HomeContent />
        </Container>
        <Footer />
        <Box bgcolor={"white"} p={2}>
          <Typography textAlign={"center"} variant="h6">
            Built by Ex-Amazonians v-0.2
          </Typography>
        </Box>
      </>
    );
}

export default Home