// Auth.js
import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";

import "../App.css";
import Nav from "./nav";
import { useLocation } from "react-router-dom";
import { analytics } from "../helper/helper";

const CroboContainer = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const pageName =
      location.pathname.replace(/\//g, "").toUpperCase() || "HOME";
    
    analytics.screen(`REMIT ${pageName}`, {
      page_name: pageName,
      path: location.pathname,
      search: location.search,
      hash: location.hash,
    });
  }, [location]);

  return (
    <Box>
      <Box height={"55px"}>
        <Nav />
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          maxHeight: "100vh",
        }}
      >
        <Grid
          xs={12}
          sm={8}
          md={5}
          xl={3}
          item={true}
          alignItems={"center"}
        >
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CroboContainer;
