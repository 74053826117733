import React from "react";
import CroboContainer from "../components/croboContainer";
import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { handleOpenChat, analytics } from "../helper/helper"; // Import analytics

const Support = () => {
  const openWhatsApp = () => {
    var phoneNumber = "+14254765770";
    var message = "I would like to connect with Crobo support for";
    var whatsappUrl =
      "https://api.whatsapp.com/send?text=" + message + "&phone=" + phoneNumber;

    analytics.track("WhatsApp Support Opened", { phoneNumber, message }); // Track WhatsApp open event
    window.open(whatsappUrl, "_self");
  };

  return (
    <CroboContainer>
      <Container>
        <Box my={3}>
          <Typography variant="h4" textAlign={"center"}>
            Contact us at care@crobo.money
          </Typography>
          <Box display="flex" justifyContent={"center"} py={2}>
            <Button
              onClick={() => {
                handleOpenChat();
                analytics.track("Support Ticket Raised"); // Track support ticket raised event
              }}
              variant="contained"
              color="brand"
              size="large"
              startIcon={<SupportAgentIcon />}
            >
              Raise a Ticket
            </Button>
          </Box>
          <Box display="flex" justifyContent={"center"} py={1}>
            <IconButton
              component="a"
              href="https://www.linkedin.com/company/crobo-money"
              target="_blank"
              aria-label="LinkedIn"
              onClick={() => analytics.track("LinkedIn Icon Clicked")} // Track LinkedIn icon click
            >
              <LinkedInIcon sx={{ fontSize: "40px", color: "black" }} />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.instagram.com/crobo_money"
              target="_blank"
              aria-label="Instagram"
              onClick={() => analytics.track("Instagram Icon Clicked")} // Track Instagram icon click
            >
              <InstagramIcon sx={{ fontSize: "40px", color: "black" }} />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.twitter.com/crobo_money"
              target="_blank"
              aria-label="Twitter"
              onClick={() => analytics.track("Twitter Icon Clicked")} // Track Twitter icon click
            >
              <TwitterIcon sx={{ fontSize: "40px", color: "black" }} />
            </IconButton>
            <IconButton
              component="a"
              onClick={openWhatsApp}
              aria-label="WhatsApp"
            >
              <WhatsAppIcon sx={{ fontSize: "40px", color: "black" }} />
            </IconButton>
          </Box>
          <Typography variant="h5" textAlign={"center"}>
            &copy;2024 Crobo. All rights reserved{" "}
          </Typography>
        </Box>
      </Container>
    </CroboContainer>
  );
};

export default Support;
