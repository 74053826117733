import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";

const ReferralProgramTerms = () => {
  return (
    <Container>
      <Box my={5}>
        <Typography variant="h4" align="center" gutterBottom>
          Referral Program Terms and Conditions
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Effective Date: September 1, 12:00 AM PST
          <br />
          End Date: September 31, 11:59 PM PST
        </Typography>
        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom>
          Welcome to our Referral Program! By participating in this program, you
          agree to the following terms and conditions:
        </Typography>

        <Box my={3}>
          <Typography variant="h5" gutterBottom>
            Eligibility:
          </Typography>
          <Typography variant="body1">
            - The referral program is open to all registered users of our
            platform.
            <br />- Both the referrer and the referred must be legitimate users
            of the service.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h5" gutterBottom>
            Referral Reward:
          </Typography>
          <Typography variant="body1">
            - Both the referrer and the referred user will receive a $10 Amazon
            Gift Card.
            <br />- The referral reward will be issued once the referred user
            completes a minimum transaction of $500 on our platform.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h5" gutterBottom>
            Program Duration:
          </Typography>
          <Typography variant="body1">
            - The referral program begins on September 1, 12:00 AM PST and ends on
            September 31, 11:59 PM PST.
            <br />- Transactions must be completed within this timeframe to
            qualify for the referral reward.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h5" gutterBottom>
            Referral Limit:
          </Typography>
          <Typography variant="body1">
            - There is no limit to the number of referrals a user can make. You
            can refer as many friends and family members as you like.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h5" gutterBottom>
            Reward Conditions:
          </Typography>
          <Typography variant="body1">
            - The referred user must complete a transaction of at least $500.
            <br />- The referrer will only receive the reward if the referred
            user completes the minimum transaction requirement within the
            program duration.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h5" gutterBottom>
            Reward Issuance:
          </Typography>
          <Typography variant="body1">
            - Amazon Gift Cards will be emailed to the email addresses
            registered with the referrer and referred user's accounts within 7
            days after the referred user completes the qualifying transaction.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h5" gutterBottom>
            Fraudulent Activity:
          </Typography>
          <Typography variant="body1">
            - Any fraudulent or abusive behavior, gaming of the system, or
            violation of these terms will result in disqualification from the
            referral program and forfeiture of any rewards.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h5" gutterBottom>
            Modification and Termination:
          </Typography>
          <Typography variant="body1">
            - We reserve the right to modify or terminate the referral program
            at any time without prior notice. Changes to the terms and
            conditions will be posted on our website.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h5" gutterBottom>
            General Conditions:
          </Typography>
          <Typography variant="body1">
            - Participation in the referral program is voluntary.
            <br />
            - Referral rewards are non-transferable, non-exchangeable, and
            non-refundable.
            <br />
            - We are not responsible for any lost or stolen Amazon Gift Cards.
            <br />
            - In case of any dispute, our decision will be final and binding.
            <br />- The referral program is subject to all applicable federal,
            state, and local laws and regulations.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h5" gutterBottom>
            Contact Information:
          </Typography>
          <Typography variant="body1">
            - If you have any questions or concerns regarding the referral
            program, please contact our support team at{" "}
            <a href="mailto:care@crobo.money">care@crobo.money</a>.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="body1" align="center">
            By participating in this referral program, you acknowledge that you
            have read, understood, and agree to be bound by these terms and
            conditions. Thank you for being a part of our community and helping
            us grow!
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default ReferralProgramTerms;
