import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchExchangeRate } from "../../reducers/exchangeRateReducer";
import Loader from "../../components/loader";
import { fetchUsers } from "../../reducers/userReducer";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { fetchSenderUserProfile } from "../../service/api";
import { analytics, errorAnalytics } from "../../helper/helper";
import { resetTransaction } from "../../reducers/transactionReducer";

export const CreateTransaction = () => {
  const [sourceAmount, setSourceAmount] = useState("");
  const [destinationAmount, setDestinationAmount] = useState("");
  const [order, setOrder] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { exchangeRate, loading } = useSelector((state) => state.exchangeRate);
  const user = useSelector((state) => state.userAuth);
  const [error, setError] = useState();
  const [minLimit, setMinLimit] = useState(1);
  const [maxLimit, setMaxLimit] = useState(0);

  useEffect(() => {
    dispatch(fetchExchangeRate());
    fetchSenderUserProfile().then((r) => {
      setMinLimit(r.Per_transaction_min);
      setMaxLimit(r.Per_transaction_max);
    });
    dispatch(resetTransaction());
    dispatch(
      fetchUsers({
        client_id: "APIGetBitRemitWeb",
        country_code: "+91",
        phone_number: id,
      })
    );
  }, [dispatch, id]);

  const handleStartTransfer = () => {
    if (sourceAmount < minLimit) {
      setError(`Min Amount should be greater than $${minLimit}`);
      errorAnalytics("LIMIT_ERROR", {
        error: `Min limit error`,
        amount: sourceAmount,
      }); // Track limit error
    } else if (sourceAmount > maxLimit) {
      setError(`Max Amount should not be greater than $${maxLimit}`);
      errorAnalytics("LIMIT_ERROR", {
        error: `Max limit error`,
        amount: sourceAmount,
      }); // Track limit error
    } else {
      analytics.track("Transfer Started", { sourceAmount, destinationAmount }); // Track transfer start
      navigate(`/transaction/confirm/${id}/${sourceAmount}`);
    }
  };

  const handleSwitchAmount = () => {
    setOrder(order === 0 ? 1 : 0);
    analytics.track("Amount Input Switched", {
      sourceAmount,
      destinationAmount,
    }); // Track switching of amount inputs
  };

  return (
    <CroboContainer>
      <Container>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Typography textAlign={"center"} paddingTop={1}>
            Please note: Maximum transfer limit per transaction is $
            {maxLimit ? (
              maxLimit
            ) : (
              <CircularProgress size="14px" color="brand" />
            )}
          </Typography>
          <Typography variant="h5" paddingTop={2} textAlign={"center"}>
            Today's exchange rate
          </Typography>
          {loading && user.loading ? (
            <Loader />
          ) : (
            <Typography variant="h5" paddingTop={1} textAlign={"center"}>
              $1 = ₹{exchangeRate}
            </Typography>
          )}
        </Box>

        <Box
          display={"flex"}
          flexDirection={order === 0 ? "column" : "column-reverse"}
        >
          <OutlinedInput
            placeholder="Enter Amount"
            value={sourceAmount}
            startAdornment={
              <InputAdornment position="start">
                <img
                  src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                  height={30}
                  width={40}
                  alt="$"
                />
              </InputAdornment>
            }
            endAdornment={<InputAdornment position="end">USD</InputAdornment>}
            sx={{ marginTop: 3 }}
            type="number"
            onChange={(e) => {
              setSourceAmount(e.target.value);
              setDestinationAmount((e.target.value * exchangeRate).toFixed(2));
              analytics.track("Source Amount Entered", {
                sourceAmount: e.target.value,
              }); // Track source amount entered
            }}
            fullWidth
          />
          <Box justifyContent={"center"} display={"flex"}>
            <Button
              variant="contained"
              color="brand"
              onClick={handleSwitchAmount}
              sx={{
                borderRadius: "50%",
                width: 56,
                height: 56,
                minWidth: 0,
                marginTop: 3,
              }}
            >
              <SwapVertIcon sx={{ fontSize: "50px", color: "white" }} />
            </Button>
          </Box>
          <OutlinedInput
            placeholder="Enter Amount"
            value={destinationAmount}
            startAdornment={
              <InputAdornment position="start">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                  height={30}
                  width={40}
                  alt="₹"
                />
              </InputAdornment>
            }
            endAdornment={<InputAdornment position="end">IND</InputAdornment>}
            sx={{ marginTop: 3 }}
            type="number"
            onChange={(e) => {
              setSourceAmount((e.target.value / exchangeRate).toFixed(2));
              setDestinationAmount(e.target.value);
              analytics.track("Destination Amount Entered", {
                destinationAmount: e.target.value,
              }); // Track destination amount entered
            }}
            fullWidth
          />
        </Box>
        {error && (
          <Typography textAlign={"center"} color={"red"}>
            {error}
          </Typography>
        )}
        <Button
          color="brand"
          variant="contained"
          fullWidth
          size="large"
          sx={{ marginTop: 3 }}
          onClick={handleStartTransfer}
          disabled={
            !sourceAmount ||
            sourceAmount === 0 ||
            !destinationAmount ||
            destinationAmount === 0
          }
        >
          <Typography variant="h6" color={"black"}>
            Start Transfer
          </Typography>
        </Button>
      </Container>
    </CroboContainer>
  );
};
