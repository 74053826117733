import React, { useRef } from "react";
import { Grid, Paper, Typography, IconButton, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const testimonials = [
  {
    name: "Yashwanth",
    testimonial:
      "Crobo has a user-friendly interface, robust security measures, and real-time exchange rate updates. Very responsive customer support. The app's efficiency and security make it a promising choice for hassle-free financial transactions.",
  },
  {
    name: "Satvik",
    testimonial:
      "I just tried to send money using Crobo! It was instant! This is amazing! Love this service! Will definitely spread the word! Keep up the great work!",
  },
  {
    name: "Karthik",
    testimonial: "Will recommend the app to my friends as well, all the best!",
  },
  {
    name: "Keerthika",
    testimonial:
      "I just sent money using this app. The onboarding process was seamless and was done in minutes. Transfer was super quick and they give you a better rate than google. Love it!!! Would definitely recommend it.",
  },
  {
    name: "Madhu",
    testimonial:
      "I'm really impressed by the transfer speeds. It takes more time for a direct deposit to different accounts in the US. This is equivalent to Zelle/UPI for an international transfer.",
  },
  {
    name: "Sanjay",
    testimonial:
      "The process was incredibly user-friendly, making it easy for me to send money to my family in India. What really stood out was the speed of the transaction - it was almost instantaneous.",
  },
];

const TestimonialsCarousel = () => {
  const scrollContainerRef = useRef(null);
  const carouselWidth = 320;

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      if (scrollContainerRef.current.scrollLeft === 0) {
        // Scroll to the end if at the start
        scrollContainerRef.current.scrollTo({
          left: scrollContainerRef.current.scrollWidth,
          behavior: "smooth",
        });
      } else {
        scrollContainerRef.current.scrollBy({
          left: -carouselWidth,
          behavior: "smooth",
        });
      }
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      if (
        scrollContainerRef.current.scrollLeft +
          scrollContainerRef.current.clientWidth >=
        scrollContainerRef.current.scrollWidth
      ) {
        // Scroll to the start if at the end
        scrollContainerRef.current.scrollTo({
          left: 0,
          behavior: "smooth",
        });
      } else {
        scrollContainerRef.current.scrollBy({
          left: carouselWidth,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <Grid container alignItems="center">
      <Grid
        item
        display={"flex"}
        overflow={"scroll"}
        ref={scrollContainerRef}
        sx={{
          paddingY: 2,
          overflowY: "hidden",
          scrollbarWidth: "none", // For Firefox
          "&::-webkit-scrollbar": {
            display: "none", // For Chrome, Safari, and Opera
          },
        }}
      >
        {testimonials.map((t) => (
          <Grid
            key={t.name}
            sx={{ minWidth: `${carouselWidth}px`, marginTop: 2 }}
            display={"flex"}
            justifyContent={"center"}
          >
            <Paper
              sx={{
                marginInlineEnd: "20px",
                p: 3,
                bgcolor: "brandDark.main",
                color: "white",
              }}
              elevation={4}
            >
              <Typography
                textAlign={"center"}
                variant="h5"
                paddingBottom={3}
                color={"white"}
              >
                {t.name}
              </Typography>
              <Typography textAlign={"center"} color={"white"}>
                {t.testimonial}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box display={"flex"} justifyContent={"center"} sx={{ width: "100%" }}>
        <Box display={"flex"} flexDirection={"row"}>
          <Grid item>
            <IconButton onClick={scrollLeft} aria-label="Scroll left">
              <ArrowBackIosNewIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={scrollRight} aria-label="Scroll right">
              <ArrowForwardIosIcon />
            </IconButton>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default TestimonialsCarousel;
