// src/VerificationStepper.js
import React from 'react';
import Stepper from './stepper';
const steps = ['Bank Verification', 'ID Verification', 'Verification Complete'];

const VerificationStepper = ({ state }) => {
  const determineStep = () => {
    const { bank, kyc, bankVerification, bankBypass } = state;
    if (bank === 0 && !bankBypass) return 0;
    if (bank === 1 || bankBypass) {
      if (
        kyc !== 1 ||
        bankVerification === 0 ||
        bankVerification === 2 ||
        bankVerification === 3
      )
        return 1;
      if (kyc === 1 && bankVerification === 1) return 2;
    }
    return 0;
  };

  return (
    <Stepper activeStep={determineStep()} steps={steps} />
  );
};

export default VerificationStepper;
