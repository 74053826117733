import {
    Box,
    Button,
    Container,
    Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

const RecipientsCard = ({
    id,
    name,
    phone,
    bank_account_number,
    bank_name,
    ifsc,
    email,
    pan,
    onClickEdit,
    is_bank_added
}) => {

    return (
        <Box
            sx={{
                p: 2,
                boxShadow: 1,
                borderRadius: 4,
                my: 2,
                bgcolor: "grey.200",
                cursor: "default",
                "&:hover": {
                    boxShadow: 5,
                }
            }}
        >
            <Box>
                <Container sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: 0,
                    alignItems: 'center',
                }}>
                    <Typography variant="body1" lineHeight={1.2}>
                        Name:
                    </Typography>
                    <Typography color="text.secondary" variant="body1" lineHeight={1.2}>
                        {name}
                    </Typography>
                </Container>

                <Container sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: 0,
                    alignItems: 'center',
                }}>
                    <Typography variant="body1" lineHeight={1.2}>
                        Email:
                    </Typography>
                    <Typography color="text.secondary" variant="body1" lineHeight={1.2}>
                        {email}
                    </Typography>
                </Container>

                {/* <Container sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: 0,
                    alignItems: 'center'
                }}>
                    <Typography variant="body2" lineHeight={1}>
                        Phone:
                    </Typography>
                    <Typography color="text.secondary" variant="body2" lineHeight={1}>
                        {phone}
                    </Typography>
                </Container> */}

                <Container sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: 0,
                    alignItems: 'center'
                }}>
                    <Typography variant="body2" lineHeight={1}>
                        Bank Account Number:
                    </Typography>
                    <Typography color="text.secondary" variant="body2" lineHeight={1}>
                        {bank_account_number}
                    </Typography>
                </Container>

                <Container sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: 0,
                    alignItems: 'center'
                }}>
                    <Typography variant="body2" lineHeight={1}>
                        IFSC Code:
                    </Typography>
                    <Typography color="text.secondary" variant="body2" lineHeight={1}>
                        {ifsc}
                    </Typography>
                </Container>

                {/* <Container sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: 0,
                    alignItems: 'center'
                }}>
                    <Typography variant="body2" lineHeight={1}>
                        Bank Name:
                    </Typography>
                    <Typography color="text.secondary" variant="body2" lineHeight={1}>
                        {bank_name}
                    </Typography>
                </Container> */}
                <Container sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingLeft: 0,
                    alignItems: 'center'
                }}>
                    {/* <Typography variant="body2" lineHeight={1}>

                    </Typography>
                    <Button onClick={() => {
                        onClickEdit({
                            id,
                            name,
                            phone,
                            bank_account_number,
                            bank_name,
                            ifsc,
                            pan,
                            email
                        })
                    }} color="brand">
                        Edit
                    </Button> */}
                    <Link
                        to={`/transaction/create/${phone}`}
                        style={{
                            ...(is_bank_added !== 1 && {  pointerEvents: 'none' })
                        }}
                    >
                        <Button sx={{
                            backgroundColor: 'brand.main',
                            "&:hover": {
                                backgroundColor: "brand.dark",
                            }
                        }} variant="contained" disabled={is_bank_added !== 1}>
                            <Typography textAlign={"center"} color={"#FFF"}>
                                Start Transfer
                            </Typography>
                        </Button>
                    </Link>
                </Container>
            </Box>
            <ToastContainer
                hideProgressBar
                position="top-center"
            />
        </Box>
    );
};

export default RecipientsCard;
